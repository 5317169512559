import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './Moss.module.scss'

const Moss = () => {
  const data = useStaticQuery(graphql`
    query aboutUsMossQuery {
      image1: file(relativePath: { eq: "images/aboutUsMoss1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "images/aboutUsMoss2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "images/aboutUsMoss3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "images/aboutUsMoss4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "images/aboutUsMoss5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className={styles.moss}>
      <div className={styles.row}>
        <div className={styles.colCopy}>
          <h3 className="t-heading-m">We turn science into practice</h3>
          <p className="t-bold t-italic">
            Mosses are complex plant systems with useful medical, culinary,
            construction, biotech, and horticulture applications.
          </p>
          <p>
            We foster strong connections with professors and research centers at
            top universities such as the Rotterdam School of Management and the
            Warwick Business School and tear down the so-called ‘ivory tower’.
            It’s an expression. It means that we use our access to cutting-edge
            scientific knowledge to make academic research on responsible
            business useful in the real world. Easy to say, difficult to do! We
            apply design thinking principles to create simple tools and
            frameworks from complex academic theory and then use our didactic
            and andragogic expertise to build practical workshops, trainings,
            and consulting services. We bring the science, you put it into
            practice.
          </p>
        </div>

        <div className={styles.colImg}>
          <Img fluid={data.image1.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.colCopy}>
          <h3 className="t-heading-m">We maximize positive societal impact</h3>
          <p className="t-bold t-italic">
            Mosses are an environmentally benign way to control erosion,
            sequester carbon, and identify air and water pollutants.
          </p>
          <p>
            All socio-ecological problems are caused by dysfunctional systems
            behavior. Societal issues don’t just happen, they are the result of
            the interactions between many different variables. Social
            Enterprises (SEs) should therefore seek to understand the complex
            interdependencies of those variables, in order to allocate limited
            resources as effectively as possible to meet their impact
            objectives. This approach is called Systems Thinking. Still
            following? We infuse all our services with this perspective so that
            businesses tackle the root causes rather than the symptoms of
            socio-ecological problems. What’s more, systems are dynamic and
            change over time so as a bonus, SEs that tackle the causes rather
            than the symptoms are better placed than others to withstand shocks.
            That’s called building organizational resilience. We level up your
            resilience and positive societal impact.
          </p>
        </div>

        <div className={styles.colImg}>
          <Img fluid={data.image2.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.colCopy}>
          <h3 className="t-heading-m">We reduce negative societal impact</h3>
          <p className="t-bold t-italic">
            Mosses are never parasitic but can inhibit penetration of water to
            other plant roots unless drainage is applied.
          </p>
          <p>
            Social Enterprises (SEs) and their stakeholders often intuitively
            feel - or design impact measurements showing - that they are solving
            a societal problem. This concerns us. We’re not saying that SEs
            aren’t creating positive impact, but they may not be aware of the
            negative impact they have. Without a holistic view of all their
            impact in the system including the values overlooked or destroyed,
            it is impossible to know whether the SE is doing more good than it
            is harm. Unfortunately, we see it happen all the time. In extreme
            cases this can mean that dysfunctional system behavior is validated
            by the existence of the SE and entrenched by the vested interests of
            their numerous stakeholders and support networks. Fear not. All is
            not lost. By first diagnosing these overlooked impacts it is then
            possible to apply corrective action. We nudge you into reducing your
            negative societal impact.
          </p>
        </div>

        <div className={styles.colImg}>
          <Img fluid={data.image3.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.colCopy}>
          <h3 className="t-heading-m">
            We build resilience in the societies that need us the most
          </h3>
          <p className="t-bold t-italic">
            Mosses are tolerant and thrive in areas that are under stress,
            neglected, or experience harsh conditions.
          </p>
          <p>
            Where most consultancies focus on the visible minority, we opt for
            the overlooked majority. On a global scale, this means concentrating
            on developing and emerging countries with high-impact potential
            where socio-ecological challenges are underserved by existing
            institutions and infrastructures. South/Southeast Asia is a
            particularly suitable area where the conditions for fostering Social
            Enterprise (SE) are rapidly developing. Regionally, this means
            prioritizing SEs that are founded and run by locals, develop out of
            grassroots initiatives, and engage with systemically important
            socio-ecological challenges. This is borne out of our conviction
            that local communities understand best the complexities of local
            problems, and there is room for facilitation in empowering local
            solutions rather than imposing foreign ones. We empower you to serve
            the overlooked.
          </p>
        </div>

        <div className={styles.colImg}>
          <Img fluid={data.image4.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.colCopy}>
          <h3 className="t-heading-m">We facilitate growth in multiple ways</h3>
          <p className="t-bold t-italic">
            Mosses are growth stimulants as they repair damage to their DNA,
            provide nitrogen to the ecosystem, and reproduce through spores as
            well as insect pollination.
          </p>
          <p>
            Having multiple intervention points in a system leverages more
            impact potential. Like mosses, we grow everywhere. Vigorously. Not
            only do we work directly with Social Enterprises (SEs) through
            consulting or indirectly with Social enterprises through workshops
            at local incubators and accelerators, we also recognize the value in
            promoting social entrepreneurship generally through collaborations
            with local educational institutions to educate the leaders of
            tomorrow on responsible business. We are teachers as well, after
            all. Finally, we provide SEs with access to capital through
            collaborations with local and international funding bodies as well
            as through our revolving MOSS fund that invests in high-impact
            opportunities. We’re a lean green growth machine.
          </p>
        </div>

        <div className={styles.colImg}>
          <Img fluid={data.image5.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default Moss
