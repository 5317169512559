import React from 'react'

import Hero from '@components/pages/about-us/Hero'
import Moss from '@components/pages/about-us/Moss'
import Root from '@layouts/Root'
import Team from '@components/pages/about-us/Team'
import Seo from '@components/utils/Seo'

const IndexPage = () => (
  <Root>
    <Seo title="About Us" />

    <Hero />
    <div className="wrapper-l">
      <hr />
    </div>
    <Moss />
    <div className="wrapper-l section">
      <hr />
    </div>
    <Team />
  </Root>
)

export default IndexPage
