import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import styles from './Member.module.scss'

const Member = ({
  children,
  linkedin,
  listening,
  name,
  photo,
  reading,
  role,
}) => (
  <div className={styles.member}>
    <div className={styles.photo}>
      <Img fluid={photo} alt={`photo of ${name}`} />
    </div>

    <div className={styles.copy}>
      <h3 className={styles.copy__name}>{name}</h3>

      {role && <div className={styles.copy__role}>{role}</div>}

      <div className={styles.copy__connect}>
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
        )}
      </div>

      <div className={styles.copy__description}>{children}</div>
    </div>

    <div className={styles.extra}>
      <div className={styles.extra__item}>
        <div className={styles.extra__key}>Currently reading:</div>
        <div className={styles.extra__value}>{reading}</div>
      </div>

      <div className={styles.extra__item}>
        <div className={styles.extra__key}>Currently listening:</div>
        <div className={styles.extra__value}>{listening}</div>
      </div>
    </div>
  </div>
)

Member.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
  reading: PropTypes.string,
  listening: PropTypes.string,
  linkedin: PropTypes.string,
  photo: PropTypes.object.isRequired,
}

Member.defaultProps = {
  reading: '-',
  listening: '-',
}

export default Member
