import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Intro from '@components/ui/Intro'
import Member from './Member'
import styles from './Team.module.scss'

const Team = () => {
  const data = useStaticQuery(graphql`
    query teamQuery {
      teamSander: file(relativePath: { eq: "images/teamSander.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 184, grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamSteve: file(relativePath: { eq: "images/teamSteve.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 184, grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamChayanat: file(relativePath: { eq: "images/teamChayanat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 184, grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="section" id="team">
      <Intro sectionTitle="Our Team" wrapper="wrapper-s">
        <p>
          MOSS is powered by an enthusiastic team of intelligent, ideological,
          and humorous decision-makers motivated by the same drive to challenge
          the status quo.
        </p>
      </Intro>

      <div className="wrapper-l">
        <div className={styles.membersWrapper}>
          <Member
            linkedin="https://www.linkedin.com/in/sanderfleuren/"
            listening="Caamp, Jon Bellion, The Midnight"
            name="Sander Fleuren"
            photo={data.teamSander.childImageSharp.fluid}
            reading="Change Everything by Christian Felber"
            role="Managing Director"
          >
            <p>
              MOSS Consultants & Capital is my answer to the question: what is
              the most effective way to maximize the impact of social
              entrepreneurs (SEs)? It is the culmination of over 10 years of
              advising SEs in Africa, Asia, and Europe, and witnessing
              first-hand the transformative power of the fourth sector, while
              growing increasingly concerned with its tendencies to address
              symptoms rather than the root causes of societal issues.
            </p>
            <p>
              Having spent more than 5 years in academia including as a
              researcher, senior lecturer, and learning & development manager at
              the Rotterdam School of Management, I recognized the value of
              making cutting-edge scientific knowledge practicable for
              entrepreneurs.
            </p>
            <p>
              It all came together in the form of a charismatic professor who,
              besides introducing me to Systems Thinking, agreed to be my friend
              on the condition that I drop everything to pursue my dream of
              making a difference.
            </p>
          </Member>

          <Member
            linkedin="https://www.linkedin.com/in/steve-kennedy-18800725/"
            listening="Rainbow Kitten Surprise"
            name="Dr. Steve Kennedy"
            photo={data.teamSteve.childImageSharp.fluid}
            reading="Business Dynamics: Systems Thinking and Modeling for a Complex World by John Sterman"
            role="Scientific Advisor"
          >
            <p>
              I believe that social entrepreneurs can change the world. They can
              provide solutions to our grand sustainability challenges such as
              climate change and inequality, and positively disrupt our
              industries to new ways of working. Yet, I see that it is common
              for social entrepreneurs to not really understand the systems they
              are seeking to change. This leads to ineffective solutions, lost
              time and resources.
            </p>
            <p>
              My work as Associate Professor at the Rotterdam School of
              Management uses systems thinking to understand how organizations
              may best make positive systems changes. I enjoy advising MOSS in
              my free time as it provides me with a vehicle to have practical
              impact and to offer this promising consultancy the support it
              needs to foster systems entrepreneurship across emerging markets.
            </p>
          </Member>

          <Member
            linkedin="https://www.linkedin.com/in/chayanat-supasongklod-0246ab194/"
            listening="Seafret"
            name="Chayanat Supasongklod"
            photo={data.teamChayanat.childImageSharp.fluid}
            reading="The Little Book of Lykke by Meik Wiking"
            role="Junior Consultant"
          >
            <p>
              Making change happen is not always an easy task and making the
              right change is possibly the hardest.
            </p>
            <p>
              For the past 7 years, I have been working as a content creator and
              volunteer within different communities and organizations. In that
              time I have met many people and organizations working hard to
              bring change to their communities.
            </p>
            <p>
              These experiences have led me to wonder what could be a better way
              for purpose-driven organizations to make the right and
              long-lasting change happen.
            </p>
            <p>
              I believe that MOSS can help support those change initiatives
              bringing positive change to society.
            </p>
          </Member>
        </div>
      </div>
    </section>
  )
}

export default Team
