import React from 'react'

import Intro from '@components/ui/Intro'

const Hero = () => (
  <header className="hero">
    <Intro pageTitle="About Us">
      <p className="t-bold">
        MOSS is a consultancy and financier that aims to maximize the societal
        impact of social entrepreneurs in developing and emerging countries by
        providing practical workshops, trainings, and mentoring services
        developed from cutting-edge scientific socio-ecological responsible
        business expertise through our affiliation with top business schools.
      </p>

      <p>
        Too complicated? As academics we get that a lot, though we too prefer
        simpler explanations. In fact, we believe that the latest scientific
        knowledge should be made easier to understand and implement for
        businesses. That’s really what MOSS does: we make science practicable.
        Here, we’ll prove it using...er, moss.
      </p>
    </Intro>
  </header>
)

export default Hero
